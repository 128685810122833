import React from 'react'
import {Link} from 'react-router-dom'
import '../styles/styles.css'

export default function Header () {
    return (
        <header>
            <nav className="nav">
            <Link to="/"><h1 className="nav__logo">Tony Shifflett</h1></Link>
            <div className="nav__links">
                <Link to="/resume">Resume</Link>
                <Link to="/projects">Projects</Link>
                <Link to="/writing">Writing</Link>
                <a href="https://github.com/tony-shifflett">Github</a>
                <a href="https://www.linkedin.com/in/tony-shifflett-4bb60220a/">LinkedIN</a>
            </div>
            </nav>
        </header>
    )
}