import React from 'react'
import Header from '../components/Header'
import '../styles/styles.css'

export default function About (){
    return (
        <div className='about_me'>
            <Header/>
            <h1 className="about">I'm Tony.</h1>
            <div className="about">
                <p className="about_text">I'm a full-stack developer and a recent graduate of the General Assembly bootcamp, and I'm  pursuing an AS in computer science in Austin, Texas.</p>
            </div>
            <br/>
            <br/>
            <br/>
            <div className="about">
                <p className="about_text">Currently, my primary toolset is the MERN stack. I'm also familiar with Ruby on Rails, Python (Flask and Django), as well Java and C++.</p>
            </div>
        </div>
    )
}