import React from 'react';
import Header from '../components/Header';
import texts from '../json/texts.json'

export default function Writing (){
    return (
        <div>
            <Header/>
            {texts.map((text=>{
                    return(
                        <a href={text.url} key={text.title}>{text.title}</a>
                    )    
                }))}
        </div>
    )
}