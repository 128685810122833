import React from 'react'
import {Card, CardDeck, Button, ButtonGroup} from 'react-bootstrap'
import projects from '../json/projects.json'
import Header from '../components/Header'


export default function Projects () {
    return (
        <div>
            <Header/>
            <CardDeck style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'center'}}>
                {projects.map((project=>{
                    return(
                        <Card style={{width:'18rem', paddingTop:'50px'}} key={project.name}>
                            <a href ={project.live}><Card.Img variant='top' style={{height:'200px'}} src={project.image}/></a>
                            <Card.Body>
                                <Card.Title>{project.name}</Card.Title>
                                <Card.Text style={{height:'90px'}}>{project.description}</Card.Text>
                                <Card.Footer>
                                    <ButtonGroup>
                                        <Button href={project.github} style={{paddingRight:'10px'}}>Github</Button>
                                        <Button href={project.live}>Live</Button>
                                    </ButtonGroup>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                    )    
                }))}
            </CardDeck>
        </div>
    )
}