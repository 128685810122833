import React from 'react'
import Header from '../components/Header'

export default function Resume(){
    return (
        <div>
            <Header/>
            <div id="resume">
                <h1>Tony Shifflett - Full Stack Developer</h1>
                <hr/>
                <header>
                    <span>Austin, TX</span><span><a href="mailto:tshifflett.coding@gmail.com">tshifflett.coding@gmail.com</a></span> 
                </header>
                <p>I’m a full stack developer with multi-industry client-facing <br/>
                    experience in dynamic environments. I bring focus, flexibility, and a passion for <br/>learning to my projects, and am fully comfortable in challenging and time-sensitive workspaces.</p>
                <h2>SKILLS</h2>
                Git, Github, HTML5, CSS3, Javascript, jQuery, Node.js, React, Redux, MongoDB, Express, Python, Java, C++, Ruby, Rails  
                <section>
                    <h2>EXPERIENCE</h2>
                    <div>
                    <span>General Assembly</span> — Software Engineering Fellow<br/>
                    March 2021 - June 2021<br/>
                        <p>Completed an intensive 12-week immersive course focusing on <br/>full-stack software development, including the completion of 4 <br/>unit projects in individual and collaborative work environments</p><br/>
                    </div>
                    <div>
                        <span>Self-Employed Pedicab Driver </span><br/>
                        November 2012 - PRESENT<br/>
                        <p>Operated in client-facing environment performing direct sales of <br/>tours and transportation services in several major cities <br/>including Austin, Chicago, New Orleans, and Washington DC, <br/>as well as music festivals throughout the US</p><br/>
                    </div>
                    <div>
                        <span>Galvez Restaurant, New Orleans</span> — Line Cook / Event Assistant<br/>
                        2009 - 2015<br/>
                        <p>Operated Garde Manger Fry, and Grill stations in a <br/>from-scratch   Creole/Spanish kitchen. Took on additional <br/>client-facing roles including: handling sales inquiries, <br/>coordinating menu tastings, conducting venue tours</p><br/>
                    </div>
                </section>
                <h2>EDUCATION</h2>
                <div>
                    <span>General Assembly</span> — Software Engineering Immersive <br/>
                    March 2021 - June 2021 Certificate of Completion<br/>
                    <p>Intensive 12-week immersive course in full-stack development</p><br/>
                </div>
                <div>
                    <span>Austin Community College</span> — Computer Science <br/>
                    March 2020 - Present AS<br/>
                </div>
                <div>
                    <span>University of New Orleans</span> — Anthropology <br/>
                    August 2007 - May 2010 (90 credits completed)<br/>
                </div>
            </div>
        </div>
    )
}