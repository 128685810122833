import './App.css';
import {Route, Switch} from "react-router-dom";
import About from './pages/About';
import Resume from './pages/Resume';
import Projects from './pages/Projects';
import Writing from './pages/Writing';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path = '/' render = {(rp)=><About/>}/>
        <Route exact path = '/resume' render = {(rp)=><Resume/>}/>
        <Route exact path = '/projects' render = {(rp)=><Projects/>}/>
        <Route exact path = '/writing' render = {(rp)=><Writing/>}/>
      </Switch>
    </div>
  );
}

export default App;
